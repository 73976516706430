import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/Logo_white.png'

const Header = (props) => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img src={pic01} alt="" className="img"/>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Software - Beratung - Projektleitung</h1>
        <p>
          Sie wollen eine <a href="#42" onClick={() => props.onOpenArticle('software')}><b>Softwareentwicklung</b></a> professionell und erfolgreich im Kosten- und Zeitrahmen realisieren?
        </p>
        <p>
          Sie benötigen <a href="#42" onClick={() => props.onOpenArticle('consulting')}><b>IT Beratung</b></a> oder
           suchen Unterstützung in der <a href="#42" onClick={() => props.onOpenArticle('project')}><b>IT Projektleitung</b></a> für ihr nächstes Softwareprojekt?
        </p>
        <p>
          Dann <a href="#42" onClick={() => props.onOpenArticle('contact')}><b>kontaktieren</b></a> Sie uns.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('software')
            }}
          >
            Software
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('consulting')
            }}
          >
            Beratung
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('project')
            }}
          >
            Projektleitung
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            <b>Kontakt</b>
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
