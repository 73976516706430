import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; 2021 Smarwag UG (haftungsbeschränkt) / 
        <a href="#42" onClick={() => props.onOpenArticle('about')}>Impressum</a> /
        <a href="#42" onClick={() => props.onOpenArticle('privacy')}>Datenschutz</a>
        </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
