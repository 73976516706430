import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/program.jpg'
import pic02 from '../images/consulting.jpg'
import pic03 from '../images/project.jpg'
import pic04 from '../images/logo_white2.png'
import Reaptcha from 'reaptcha';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: true,
      email: '',
      lastName: '',
      message: '',
      isAccepted: false,
      submitAllowed: false,
      errorMessage: '',
      isSubmitting: false,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeIsAccepted = this.handleChangeIsAccepted.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateVerified() {
    this.setState({
      submitAllowed: (this.state.lastName.trim().length > 0) &&
        (this.state.email.trim().length > 0) &&
        (this.state.email.includes('@')) &&
        (this.state.message.trim().length > 0) &&
        this.state.isAccepted
    });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value }, () => { this.validateVerified(); });
  }

  handleChangeLastName(event) {
    this.setState({ lastName: event.target.value }, () => { this.validateVerified(); });
  }

  handleChangeMessage(event) {
    this.setState({ message: event.target.value }, () => { this.validateVerified(); });
  }

  handleChangeIsAccepted(event) {
    this.setState({ isAccepted: event.target.checked }, () => { this.validateVerified(); });
  }

  async handleSubmit(event) {
    // disable 
    event.preventDefault();
    this.setState({ isSubmitting: true });
    await fetch('https://www.smarwag.de/api/v1/system/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lastName: this.state.lastName,
        message: this.state.message,
        email: this.state.email
      })
    }).catch(error => console.log(error));
    this.setState({ isSubmitting: false });
    this.props.onCloseArticle();
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true
    }, () => { this.validateVerified(); });
  };

  onExpire = recaptchaResponse => {
    this.setState({
      verified: false
    }, () => { this.validateVerified(); });
  };

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
        role="button"
        aria-label="Close"
        tabIndex={0}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="software"
          className={`${this.props.article === 'software' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Softwareentwicklung</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Sie benötigen eine qualitativ hochwertige Softwarelösung, die auf Ihren Geschäftsbedarf und Ihre Prozesse abgestimmt ist?
            Wir bieten Ihnen für Ihr nächstes Softwareprojekt:
            <br />
            <br />
            - mehrjährige Softwareentwicklungserfahrung<br />
            - Java Full Stack Programmierung (Spring, MyBatis, HTML, JS, Tomcat, SQL)<br />
            - Multi-Tier Know-How, vom Front-End bis zum Back-End in der Cloud<br />
            - Embedded Softwareentwicklung in C, Rust, C++<br />
            - Bare Metal / Realtime Programmierung im Industrieumfeld<br />
            - Softwarerdesign und Softwarearchitektur auf ihre Anforderungen abgestimmt<br />
            - Entwickelt nach agilen Entwicklungsmethoden wie z.B. Scrum, Kanban<br />
          </p>
          <p>
            Wir unsterstützen sowohl Ihr Team bei Softwareentwicklungsengpässen, führen aber auch vollständige offsite Entwicklungen durch.
            Kontaktieren Sie uns einfach, gemeinsam finden wir eine passende Lösung für Ihr Softwareentwicklungsprojekt.
          </p>
          {close}
        </article>

        <article
          id="consulting"
          className={`${this.props.article === 'consulting' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">IT Beratung</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Die digitale Transformation hat zunehmend Einfluß auf den Erfolg Ihres Unternehmens.
            Wir unsterstützen Sie bei der Entwicklung der richtigen Strategie Ihre Geschäftsprozesse weiter zu digitalisieren.
            Die Fragen nach der Umsetzung und richtigen Vorgehensweise, sowie der passenden Technologie, versuchen wir
            mit Ihnen zu beantworten.
          </p>
          <p>
            Die langjährige Softwareentwicklungserfahrung ermöglicht uns bei einem Software Audit Ihnen Optimierungsvorschläge auf Basis
            Ihrer aktuellen digitalen Prozesse und der genutzten Technologien zu unterbreiten. Dabei betrachten wir auch Ihre
            Systemlandschaft und eingesetzten TechStacks. Auf dieser Analyse basierend ermittlen wir
            optimale Softwarelösungen und Prozesse, um Ihre Ziele im gestzten Budget- und Zeitrahmen zu realisieren.
          </p>
          <p>
            Kontaktieren Sie uns einfach, wenn Sie von unserem umfangreichen IT Know-how profitieren wollen.
          </p>
          {close}
        </article>

        <article
          id="project"
          className={`${this.props.article === 'project' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">IT Projektleitung</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Sie benötigen Unterstützung bei der Projektleitung eines IT-Projektes?
            Wir bieten Ihnen den technischen Hintergrund die Komplexität heutiger IT-Projekte effektiv
            zu planen und erfolgreich zu beherrschen.
          </p>
          <p>
            Wir helfen Ihnen bei der Planung Ihrer Projekte, identifizieren Ziele und monitoren den Fortschritt.
            Wir moderieren Ihre Teams, damit alle sich über die Erwartungen, Ergebnisse und Fristen im Klaren sind.
            Die verfügbaren Ressourcen werden kontinuierlich bewertet, um Engpässe möglichst früh zu erkennen.
          </p>
          <p>
            Hinzu überwachen und kontrollieren wir den Projektfortschritt mit Hinblick auf das Budget,
            den Zeitvorgaben und dem geplanten Umfang und geben Ihnen kontinuierlich Fortschrittsberichte,
            damit Sie jederzeit über den aktuellen Stand Ihres IT-Projektes informiert sind.
          </p>
          <p>
            Kontaktieren Sie uns einfach, wir finden die passende Form der Zusammenarbeit.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Impressum</h2>
          <span className="image main">
            <img src={pic04} alt="" />
          </span>
          <p>
            Angaben gemäß § 5 TMG:
          </p>
          <p>
            Smarwag UG (haftungsbeschränkt)<br />
          Im Weiher 133<br />
          69121 Heidelberg<br />
            <br />
          Kontakt:<br />
          06221 - 4161040<br />
          info(at)smarwag.de<br />
          www.smarwag.de<br />
            <br />
          Registergericht: Amtsgericht Mannheim<br />
          Registernummer: HRB 733878<br />
          Vertretungsberechtigter Geschäftsführer: Dipl.-Inform. Marcel Wagner<br />
          Umsatzsteuer-Identifikationsnummer nach § 27a UStG: DE325035647<br />
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="addressfs">Anrede (optional)</label>
              <fieldset id="addressfs">
                <input type="radio" id="hr" name="address" value="Herr" />
                <label htmlFor="hr">Herr</label>
                <input type="radio" id="fr" name="address" value="Frau" />
                <label htmlFor="fr">Frau</label>
                <input type="radio" id="ne" name="address" value="Neutral" />
                <label htmlFor="ne">Neutral</label>
              </fieldset>
            </div>
            <div className="field half">
              <label htmlFor="lastName">Nachname</label>
              <input id="lastName" type="text" value={this.state.lastName} onChange={this.handleChangeLastName} />
            </div>
            <div className="field">
              <label htmlFor="email">E-Mail-Adresse</label>
              <input id="email" type="email" value={this.state.email} onChange={this.handleChangeEmail} />
            </div>
            <div className="field">
              <label htmlFor="company">Firma (optional)</label>
              <input id="company" type="text" />
            </div>
            <div className="field">
              <label htmlFor="message">Nachricht
                <textarea id="message" rows="5" value={this.state.message} onChange={this.handleChangeMessage} />
              </label>
            </div>
            <div className="field first">
              <Reaptcha className="field first" sitekey="6Lcn4LcZAAAAADO57afot_aKCoLYzs7WOW6X0CQ2" theme="dark" hl="de" onVerify={this.onVerify} onExpire={this.onExpire} />
            </div>
            <div className="field">
              <label ><input type="checkbox" checked={this.state.isAccepted} onChange={this.handleChangeIsAccepted} />Ich habe die Datenschutzbestimmungen gelesen und bin damit einverstanden, dass meine persönlichen Daten zu den Zwecken, in dem Umfang und für die Dauer die in der Datenschutzerklärung erläutert wurden, verarbeitet und gespeichert werden.
              </label>
            </div>
            <div className="field first">
              <ul className="actions">
                <li>
                  <button type="submit" disabled={!this.state.submitAllowed} className="special">Nachricht senden</button>
                </li>
              </ul>
            </div>
          </form>
          <div>
            {this.state.isSubmitting ? "Übertrage Daten..." : this.state.errorMessage}
          </div>
          <ul className="icons">
            <li>
              <a
                href="https://github.com/smarwag"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="privacy"
          className={`${this.props.article === 'privacy' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Datenschutzerklärung</h2>
          <p>
            Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener
          Daten durch den Websitebetreiber [Smarwag UG (haftungsbeschränkt)] informieren.<br />
            <br />
          Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
          Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen
          werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.<br />
            <br />
          Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.<br />
            <br />
            <b>Automatische Datenspeicherung</b><br />
            <br />
          Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe
          auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so protokolliert:<br />
          </p>
          Name und URL der aufgerufenen Seite<br />
          Datum und Uhrzeit zum Zeitpunkt des Zugriffes<br />
          Menge der gesendeten Daten in Byte<br />
          Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.)<br />
          Verwendeter Browser und dessen Version<br />
          Verwendetes Betriebssystem<br />
          Den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird<br />
          Die Adresse (URL) der zuvor besuchten Seite (Referrer URL)<br />
          <br />
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen,
          um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen
          bis der Vorfall endgültig geklärt ist.<br />
          <br />
          <b>Reichweitenmessung & Cookies</b><br />
          <br />
          Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter an den Browser
          des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf
          diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website.<br />
          <br />
          <b>Wie kann ich Cookies löschen?</b><br />
          <br />
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie
          immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern
          blockieren, aber alle anderen Cookies zulassen.<br />
          <br />
          Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen,
          können Sie dies in Ihren Browser-Einstellungen finden:<br />
          <br />
          <a href="https://support.google.com/chrome/answer/95647?tid=311198691">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a><br />
          <br />
          <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311198691">Safari: Verwalten von Cookies und Websitedaten mit Safari auf dem Mac</a><br />
          <br />
          <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311198691">Firefox: Cookies und Website-Daten in Firefox löschen</a><br />
          <br />
          <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311198691">Internet Explorer: Löschen und Verwalten von Cookies</a><br />
          <br />
          <a href="https://support.microsoft.com/de-at/help/4027947/microsoft-edge-delete-cookies?tid=311198691">Microsoft Edge: Cookies in Microsoft Edge löschen</a><br />
          <br />
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt
          werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser
          verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome”
          im Falle eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.<br />
          <br />
          <b>Erfassung und Verarbeitung personenbezogener Daten</b><br />
          <br />
          Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere
          persönlichen Angaben im Rahmen der Übermittlung eines Formulars, werden von uns gemeinsam mit dem Zeitpunkt und der
          IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.<br />
          <br />
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung
          der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch
          nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br />
          <br />
          Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer
          Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.<br />
          <br />
          Die Rechtsgrundlage besteht nach <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=311198691#d1e1906-1-1">Artikel 6 Absatz 1 a DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur
          Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus,
          Sie finden unsere Kontaktdaten im Impressum.<br />
          <br />
          <b>Rechte laut Datenschutzgrundverordnung</b><br />
          <br />
          Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:<br />
          <br />
          Recht auf Berichtigung (Artikel 16 DSGVO)<br />
          Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)<br />
          Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)<br />
          Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)<br />
          Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)<br />
          Widerspruchsrecht (Artikel 21 DSGVO)<br />
          Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)<br />
          <br />
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer
          Weise verletzt worden sind, können Sie sich an die <a href="https://www.bfdi.bund.de">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit</a> (BfDI) wenden.<br />
          <br />
          <b>TLS-Verschlüsselung mit https</b><br />
          <br />
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den
          Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz
          vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im
          Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
          <br />
          <b>Google Fonts Lokal Datenschutzerklärung</b><br />
          <br />
          Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited
          (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den
          Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.<br />
          <br />
          <b>Was sind Google Fonts?</b><br />
          <br />
          Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die Google
          kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede
          Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln
          wir datenschutzkonform und senden keine Daten an Google Fonts weiter.<br />
          <br />
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an
          Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
          <a href="https://developers.google.com/fonts/faq?tid=311198691"> https://developers.google.com/fonts/faq?tid=311198691.</a><br />
          <br />
          <b>Google reCAPTCHA Datenschutzerklärung</b><br />
          <br />
          Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns bestmöglich zu sichern und zu schützen. Um das zu gewährleisten, verwenden wir
          Google reCAPTCHA der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited
          (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit reCAPTCHA können wir feststellen, ob Sie auch wirklich
          ein Mensch aus Fleisch und Blut sind und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg,
          unerwünschte Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel
          lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach
          ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen
          setzen. Wie das genau funktioniert und vor allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.<br />
          <br />
          <b>Was ist reCAPTCHA?</b><br />
          <br />
          reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch nicht-menschliche Besucher schützt.
          Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art automatischer Turing-Test,
          der sicherstellen soll, dass eine Handlung im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test
          (benannt nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas übernimmt das auch
          der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die für Menschen leicht zu lösen sind, doch für
          Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken,
          um Menschen von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist
          selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann läuft das Tool im Hintergrund und
          analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem
          Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw. Captchas im Allgemeinen kommen
          immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen könnten.<br />
          <br />
          <b>Warum verwenden wir reCAPTCHA auf unserer Webseite?</b><br />
          <br />
          Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen getrost zuhause
          bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem
          Grund verwenden wir Google reCAPTCHA der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben.
          Durch die Verwendung von reCAPTCHA werden Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich ein Mensch sind. reCAPTCHA dient
          also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei
          einer Registrierung ein Bot möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten
          „zuzuspamen“. Mit reCAPTCHA können wir solche Botangriffe vermeiden.<br />
          <br />
          <b>Welche Daten werden von reCAPTCHA gespeichert?</b><br />
          <br />
          reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch wirklich von Menschen stammen.
          Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen werden
          innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt,
          bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Sie nicht während
          der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon
          Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in
          Ihrem Browser und erfasst einen Schnappschuss Ihres Browserfensters.<br />
          <br />
          Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von Daten,
          die nach unserer Erkenntnis, von Google verarbeitet werden.<br />
          <br />
          Referrer URL (die Adresse der Seite von der der Besucher kommt)<br />
          IP-Adresse (z.B. 123.123.123.123)<br />
          Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)<br />
          Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)<br />
          Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur ausführen wird gespeichert)<br />
          Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC voreingestellt haben wird gespeichert)<br />
          Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen sammeln)<br />
          Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)<br />
          <br />
          Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der
          Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten
          Google genau speichert, erfährt man von Google nicht im Detail.<br />
          <br />
          Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten gespeichert werden, stellt Google,
          selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass Daten wie
          Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden.
          Die IP-Adresse, die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren Google-Diensten
          zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind, werden die Daten
          zusammengeführt. Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.<br />
          <br />
          <b>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</b><br />
          <br />
          Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite
          besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die Daten
          sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf
          <a href="https://support.google.com/"> https://support.google.com/</a> kontaktieren.<br />
          <br />
          Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter automatisch Daten erheben,
          bearbeiten und nutzen.<br />
          <br />
          Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf <a href="https://developers.google.com/recaptcha/"> https://developers.google.com/recaptcha/</a>. Google geht hier
          zwar auf die technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und datenschutzrelevanten
          Themen sucht man auch dort vergeblich. Eine gute Übersicht über die grundsätzliche Verwendung von Daten bei Google finden Sie in der
          hauseigenen Datenschutzerklärung auf <a href="https://policies.google.com/privacy?hl=de"> https://policies.google.com/privacy?hl=de</a>.
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
